.menu-div{
    position: absolute;
    z-index: 100;
    width: 350px;
    left: 0;
    height: calc(100vh - 130px);
    background-color: white;
    box-shadow: 5px 0px 5px rgba(168, 167, 167, 0.692);
    transition: left ease-out 0.2s;
}

.menu-div-closed{
    left: -355px;
    transition: left ease-in 0.2s;
}

.open-arrow-div{
    position: absolute;
    z-index: -10;
    display: flex;
    align-items: center;
    justify-content: end;
    top: calc((100vh - 130px - 100px)/2);
    left: 310px;
    height: 90px;
    width: 90px;
    background: white;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    box-shadow: 5px 0px 5px rgba(168, 167, 167, 0.692)
}

.opacity{
    opacity: 0.5;
    transition: opacity ease-in-out 0.2s;
}

.open-arrow-div:hover{
    opacity: 1;
    transition: opacity ease-in-out 0.2s;
}

.open-arrow{
    height: 30px;
    margin-right: 10px;
    color: rgba(168, 167, 167, 0.692)
}

.open-arrow:hover{
    color: rgba(121, 118, 118, 0.8);
    transform: scale(1.2);
    transition: transform ease-in 0.2s;
}

.menu-items-div{
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-family: Serif;
    color: #2c507b;
    overflow: auto;
}

.menu-items-div::-webkit-scrollbar{
    display: none;
}

.menu-item-name-div p{
    margin: 0 15px;
}

.menu-item-name-div{
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    margin: 0 0px;
}

.active{
    background: rgba(168, 167, 167, 0.14);
}

.opened{
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.underCategory-items{
    position: absolute;
    z-index: 10;
    background-color: white;
}

.opened-undercategory{
    border-radius: 0 0 15px 15px;
    border: 1px solid rgba(196, 196, 190, 0.5);
}


.menu-full-item-div:hover{
    background: rgba(168, 167, 167, 0.14);
    color: #2c507b;
    cursor: pointer;
}

.choosed{
    background-color: #2c507b;
    color: white;
}