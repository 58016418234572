.home-div{
    position: relative;
    display: flex;
    width: 100vw;
    height: 50vh;
    overflow: hidden;
}

.fon-image{
    width: 100vw;
    height: auto;
}

.name-h{
    display: flex;
    color: white;
    margin: 30px 0 20px 30px ;
}

.name-h h1{
    margin: 0;
}

.logo-image{
    width: 100px;
}


.logo-image-div{
    background-color: white;
    border-radius: 50%;
    padding: 15px;
    margin-right: 15px;
}

.on-animation{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 300px;
    z-index: 5;
}

.name-h div{
    display: flex;
    flex-direction: column;
    justify-content: center;
}